<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <b-card header="Zoeken">
          <b-form>
            <b-form-group>
              <b-form-datepicker v-model="fromDate" placeholder="Van"/>
            </b-form-group>
            <b-form-group>
              <b-form-datepicker v-model="toDate" placeholder="Tot"/>
            </b-form-group>
            <b-form-group>
              <b-form-input type="text" v-model="personname" @change="inputPerson" @click="personname=''" placeholder="Zoek persoon..." debounce="300" />
              <ul v-if="suggestions.length" class="list-unstyled mt-2 mb-0">
                <li v-for="suggestion in suggestions" :key="suggestion.id">
                  <a href="#" @click.prevent="selectPerson(suggestion)">{{ suggestion.name }}</a>
                </li>
              </ul>
            </b-form-group>
          </b-form>
        </b-card>
      </div>
      <div class="col-md-9">
        <div class="alert alert-info" v-if="person==null">
          Kies links een begin- en einddatum en een medewerker.
        </div>
        <b-card :header="person.name" v-if="person!=null">
          <pre>{{ schedule }}</pre>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {datehelper} from "@/helpers/datehelper";

export default {
  data() {
    return {
      fromDate: datehelper().formatRevDate(),
      toDate: datehelper().addDays(14).formatRevDate(),
      personname: null,
      person: null,
      id: null,
      suggestions: [],
      schedule: []
    }
  },
  methods: {
    ...mapActions(['apiGet']),
    inputPerson() {
      this.apiGet('resources/name:'+ this.personname +' type:human').then((result) => {
        this.suggestions = result.data.data
      })
    },
    selectPerson(person) {
      this.person = person
      this.personname = person.name
      this.suggestions = []
      this.getSchedule()
    },
    getSchedule() {
      this.apiGet('resource/'+ this.person.id +'/schedules').then((result) => {
        this.schedule = result.data.data
      })
    }
  }
}
</script>